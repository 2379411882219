<template>
  <div class="MyKPIApplicationDetails">
    <!-- 审核考核详情 -->
    <van-nav-bar
      :title="$t('jxApprovalTagDetails.atdTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <van-form>
        <van-field
          v-model="form.sqnum"
          :label="$t('jxApprovalTagDetails.atdTxt2')"
          input-align="right"
          :readonly="readonly == '1'"
          :placeholder="$t('jxApprovalTagDetails.atdTxt2')"
        />
        <!-- :rules="[{ required: true, message: '请填写用户名' }]" -->
        <van-field
          v-model="form.quotaType"
          :label="$t('jxApprovalTagDetails.atdTxt3')"
          :readonly="readonly == '1'"
          input-align="right"
          :placeholder="$t('jxApprovalTagDetails.atdTxt3')"
        />
        <van-field
          v-model="form.quotaName"
          :label="$t('jxApprovalTagDetails.atdTxt4')"
          :readonly="readonly == '1'"
          input-align="right"
          :placeholder="$t('jxApprovalTagDetails.atdTxt4')"
        />
        <van-field
          v-model="form.weight"
          v-if="showWeight"
          :label="$t('jxApprovalTagDetails.atdTxt5')"
          type="number"
          :readonly="readonly == '1'"
          input-align="right"
          :placeholder="$t('jxApprovalTagDetails.atdTxt5')"
        />
        <van-field
          v-model="form.score"
          :label="
            showWeight
              ? $t('jxApprovalTagDetails.atdTxt6')
              : $t('jxApprovalTagDetails.atdTxt7')
          "
          :readonly="readonly == '1'"
          type="number"
          input-align="right"
        />
        <!-- 直接评价人工号 -->
        <van-field
          :value="
            form.zjsj_empcode ? form.zjsj_empcode + '-' + form.zjsj_empname : ''
          "
          v-if="is_zjp == '1'"
          :label="$t('jxApprovalTagDetails.atdTxt8')"
          :readonly="readonly == '1'"
          input-align="right"
        />
        <!-- 间接评价人工号 -->
        <van-field
          :value="
            form.jjsj_empcode ? form.jjsj_empcode + '-' + form.jjsj_empname : ''
          "
          v-if="is_jjp == '1'"
          :label="$t('jxApprovalTagDetails.atdTxt9')"
          :readonly="readonly == '1'"
          input-align="right"
        />
        <van-field
          v-model="form.hgyfk"
          :label="'回顾及反馈频率'"
          :readonly="readonly == '1'"
          label-width="7em"
          input-align="right"
        />
        <van-field
          v-model="form.datasupply"
          :label="'数据提供责任人'"
          :readonly="readonly == '1'"
          label-width="7em"
          input-align="right"
        />
        <van-cell
          class="desc_cell"
          :value="$t('jxApprovalTagDetails.atdTxt10')"
        />
        <van-field
          class="desc_field"
          v-model="form.quotaDesc"
          rows="8"
          autosize
          type="textarea"
          :readonly="readonly == '1'"
          :placeholder="$t('jxApprovalTagDetails.atdTxt10')"
        />
        <van-cell class="desc_cell" :value="'备注'" />
        <van-field
          class="desc_field"
          v-model="form.remark"
          rows="8"
          autosize
          :readonly="readonly == '1'"
          type="textarea"
          :placeholder="'备注'"
        />
        <van-cell class="desc_cell" :value="$t('jxMyAssTargetDetails.txt24')" />
        <van-field
          class="desc_field"
          v-model="form.pfdesp"
          rows="8"
          autosize
          :readonly="canreadonly"
          type="textarea"
          :placeholder="$t('jxMyAssTargetDetails.txt24')"
        />
      </van-form>
    </div>
    <div class="footer">
      <van-button
        type="info"
        size="small"
        v-show="readonly != '1'"
        @click="save"
        >保存</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {
  getMy360IndicatorDetail,
  getMyKpiIndicatorDetail,
  saveMyKpiIndicator
} from '@api/wxjx.js'
export default {
  data() {
    let mautoid = this.$route.query.mautoid
    let khtimeid = this.$route.query.khtimeid
    let checkflag = this.$route.query.checkflag
    let detaId = this.$route.query.detaId
    let parentTitle = this.$route.query.parentTitle
    let is360 = this.$route.query.is360
    let lastscore = this.$route.query.lastscore
    let is_zjp = this.$route.query.is_zjp
    let is_jjp = this.$route.query.is_jjp
    let readonly = this.$route.query.readonly
    let from = this.$route.query.from
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      mautoid,
      khtimeid,
      checkflag,
      detaId,
      parentTitle,
      is360,
      lastscore,
      is_zjp,
      is_jjp,
      readonly,
      from,
      form: {
        autoid: null,
        sqnum: 1,
        quotaType: '',
        quotaName: '',
        weight: 0,
        quotaDesc: '',
        score: 0,
        jjsj_empcode: '',
        jjsj_empid: 0,
        jjsj_empname: '',
        zjsj_empcode: '',
        zjsj_empid: 0,
        zjsj_empname: '',
        hgyfk: '',
        datasupply: '',
        remark: '',
        pfdesp:''
      }
    }
  },
  computed: {
    showWeight() {
      if (this.is360 == false || this.is360 == 'false') {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    if (this.is360 == true || this.is360 == 'true') {
      this.getMy360InDetail()
    } else {
      this.getMyKpiInDetail()
    }
  },
  methods: {
    // 保存
    save() {
      // KPI修改
      saveMyKpiIndicator({
        username: this.userInfo.username,
        autoid: this.form.autoid,
        itemtype: this.form.quotaType,
        itemname: this.form.quotaName,
        itemdesc: this.form.quotaDesc,
        itemper: this.form.weight,
        tgvalue: this.form.score,
        zjsj_empid: this.form.zjsj_empid,
        jjsj_empid: this.form.jjsj_empid,
        sqnum: this.form.sqnum,
        hgyfk: this.form.hgyfk,
        datasupply: this.form.datasupply,
        remark: this.form.remark,
        pfdesp: this.form.pfdesp
      }).then(res => {
        if (res.iserror == 0) {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast(this.$t('jxMyAssTargetDetails.txt14'))
            this.onClickLeft()
          }
        }
      })
    },
    onClickLeft() {
      // this.$router.push({
      //   path: "/jxApprovalTagApply", query: {
      //     title: this.$route.query.parentTitle, is360: this.$route.query.is360, mautoid: this.mautoid, lastscore: this.lastscore, is_zjp: this.is_zjp,
      //     is_jjp: this.is_jjp
      //   }
      // })
      this.$router.push({
        path: '/' + this.from || '/jxMyAssTarget',
        query: { is360: this.is360, khtimeid: this.khtimeid }
      })
    },
    // 7.4.查看某个“360指标方案”的单个指标详细信息接口
    getMy360InDetail() {
      getMy360IndicatorDetail({ autoid: this.detaId }).then(res => {
        if (res.iserror == 0) {
          this.form.sqnum = res.data[0].sqnum
          this.form.quotaType = res.data[0].typename
          this.form.quotaName = res.data[0].indicatorname
          this.form.weight = ''
          this.form.autoid = res.data[0].autoid
          this.form.quotaDesc = res.data[0].indicatordesp
          this.form.score = res.data[0].score
        }
      })
    },
    //  7.11.查看某个“KPI指标方案”的单个指标详细信息接口
    getMyKpiInDetail() {
      getMyKpiIndicatorDetail({ autoid: this.detaId }).then(res => {
        if (res.iserror == 0) {
          this.form.sqnum = res.data[0].sqnum
          this.form.quotaType = res.data[0].itemtype
          this.form.quotaName = res.data[0].itemname
          this.form.weight = res.data[0].itemper
          this.form.autoid = res.data[0].autoid
          this.form.quotaDesc = res.data[0].itemdesc
          this.form.score = res.data[0].tgvalue
          this.form.jjsj_empcode = res.data[0].jjsj_empcode
          this.form.jjsj_empid = res.data[0].jjsj_empid
          this.form.jjsj_empname = res.data[0].jjsj_empname
          this.form.zjsj_empcode = res.data[0].zjsj_empcode
          this.form.zjsj_empid = res.data[0].zjsj_empid
          this.form.zjsj_empname = res.data[0].zjsj_empname
          this.form.hgyfk = res.data[0].hgyfk
          this.form.datasupply = res.data[0].datasupply
          this.form.remark = res.data[0].remark
          this.form.pfdesp = res.data[0].pfdesp.replace(/\\n/g, '\n');
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.MyKPIApplicationDetails {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .van-form {
      /deep/.van-field {
        margin: 30px 0;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
        }
      }
      .desc_cell {
        margin-bottom: 0;
        .van-cell__value {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
      }
      .desc_field {
        margin-top: 0;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>
